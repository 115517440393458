import { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <p>&copy; 2021 白夜的幻想乡缘起</p>
                <p><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2022006218号-1</a></p>
            </footer>
        );
    }
}

export default Footer;
